import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef
} from "@angular/core";
import { fromEvent, Subscription } from "rxjs";
import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";

const HamState = {
  Open: "Open",
  Closed: "Closed"
};

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  animations: [
    trigger("ham", [
      state(
        HamState.Closed,
        style({
          "margin-left": "15px"
        })
      ),
      state(
        HamState.Open,
        style({
          "margin-left": "calc(100% - 45px)"
        })
      ),
      transition("* => *", animate("300ms ease-in-out"))
    ])
  ]
})
export class HeaderComponent implements OnInit {
  constructor(public renderer: Renderer2) {}

  private resize$sub: Subscription;

  public isDrawerOpen = false;

  public isShowHam = false;

  getOffset(el) {
    el = el.getBoundingClientRect();
    return {
      left: el.left + window.scrollX,
      top: el.top + window.scrollY,
      bottom: el.top + window.scrollY
    };
  }

  scroll(el) {
    const scroll_to = document.getElementById(el);
    let topHight = this.getOffset(scroll_to).top;
    window.scrollTo(0, topHight - 64);
  }

  scroll1() {
    window.scrollTo(0, 1950);
  }

  scroll2() {
    window.scrollTo(0, 750);
  }

  scroll3() {
    window.scrollTo(0, 2638);
  }

  scroll4() {
    window.scrollTo(0, 3134);
  }

  _resizeHandler() {
    if ((window.innerWidth || document.documentElement.clientWidth) > 960) {
      this.isDrawerOpen = false;
      this.isShowHam = false;
      this.renderer.setStyle(
        document.getElementsByTagName("body")[0],
        "overflow-y",
        "unset"
      );
    } else {
      this.isShowHam = true;
    }
  }

  toggleDrawer(ham: Element = document.getElementById("ham")): void {
    if (!this.isDrawerOpen) {
      this.renderer.addClass(ham.children[0], "is-active");
      this.renderer.setStyle(
        document.getElementsByTagName("body")[0],
        "overflow-y",
        "hidden"
      );
    } else {
      this.renderer.removeClass(ham.children[0], "is-active");
      this.renderer.setStyle(
        document.getElementsByTagName("body")[0],
        "overflow-y",
        "unset"
      );
    }
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  getHamState(): string {
    return this.isDrawerOpen ? HamState.Open : HamState.Closed;
  }

  ngOnInit() {
    this.resize$sub = fromEvent(window, "resize").subscribe(() =>
      this._resizeHandler()
    );

    this._resizeHandler();
  }
}
