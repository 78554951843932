import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"]
})
export class SliderComponent implements OnInit {
  constructor() {}

  images = [
    "assets/slider/samochod.png",
    "assets/slider/samochod.png",
    "assets/slider/samochod.png"
  ];

  ngOnInit() {}
}
