import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { IndexPageComponent } from "../app/pages/index-page/index-page.component";
import { HeaderComponent } from "../app/pages/index-page/components/header/header.component";
import { SliderComponent } from "../app/pages/index-page/components/slider/slider.component";
import { ServiceComponent } from "../app/pages/index-page/components/service/service.component";
import { KantorComponent } from "../app/pages/index-page/components/kantor/kantor.component";
import { ProductComponent } from "../app/pages/index-page/components/product/product.component";
import { FooterComponent } from "../app/pages/index-page/components/footer/footer.component";
import { AdvantagesComponent } from "../app/pages/index-page/components/advantages/advantages.component";
import { HamburgerComponent } from "../app/pages/index-page/components/header/components/hamburger/hamburger.component";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { IgxSliderModule } from "igniteui-angular";
import { FormsModule } from "@angular/forms";
import { Ng5SliderModule } from "ng5-slider";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatSliderModule } from "@angular/material/slider";
import { MatInputModule } from "@angular/material/input";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from "src/environments/environment";

@NgModule({
  declarations: [
    AppComponent,
    IndexPageComponent,
    HeaderComponent,
    SliderComponent,
    ServiceComponent,
    KantorComponent,
    ProductComponent,
    FooterComponent,
    HamburgerComponent,
    AdvantagesComponent,
  ],
  imports: [
    NgbCarouselModule,
    BrowserModule,
    AppRoutingModule,
    CarouselModule.forRoot(),
    BrowserAnimationsModule,
    IgxSliderModule,
    FormsModule,
    Ng5SliderModule,
    NgSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSliderModule,
    MatInputModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
