import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-advantages",
  templateUrl: "./advantages.component.html",
  styleUrls: ["./advantages.component.scss"]
})
export class AdvantagesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
